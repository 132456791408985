import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as ThemeUI from 'theme-ui'
import Lazyload from 'react-lazyload'

import * as Herz from '@rushplay/herz'

import * as Configuration from '../redux-configuration'
import * as AppConfig from '../app-config'
import * as Utils from '../utils'
import * as Constants from '../constants'
import { HtmlContent } from '../html-content'
import { NavLink } from '../nav-link'

import { FooterNavBar } from './footer-navbar'
import { SocialLinks } from './social-links'

export function Footer() {
  const location = ReactRouter.useLocation()

  const translate = Herz.I18n.useTranslate()

  const socialMedia = ReactRedux.useSelector((state) =>
    Configuration.getSocialMediaFooterSection(state.configuration)
  )

  const clientType = AppConfig.useClientType()

  const hideFooter =
    clientType === Constants.ClientType.MOBILE_BROWSER &&
    (location.pathname.match(/play/) || location.pathname.match(/play-live/))

  // Hides the footer on mobile devices on play-game routes
  if (location.pathname.match('starsplaypromo') || hideFooter) {
    return null
  }

  return (
    <footer>
      {/* Main Wrapper */}
      <ThemeUI.Box
        sx={{
          backgroundColor: 'dark-bg',
        }}
      >
        <ThemeUI.Box
          sx={{
            alignItems: 'center',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            maxWidth: 'container.xl',
            mx: 'auto',
            py: 5,
            px: 2,
            width: '100%',
          }}
        >
          {/* Columns Wrapper */}
          <ThemeUI.Flex
            sx={{
              'alignItems': 'flex-start',
              'flexDirection': Utils.Theming.breakpoints({
                _: 'column',
                md: 'row',
              }),

              '> *:not(:last-of-type)': {
                mb: Utils.Theming.breakpoints({
                  _: 2,
                  md: '0px',
                }),
                mr: Utils.Theming.breakpoints({
                  _: '0px',
                  md: 8,
                }),
              },
            }}
          >
            {/* Left Column */}
            <ThemeUI.Flex
              sx={{
                alignItems: 'flex-start',
                flexDirection: 'column',
                flexGrow: 1,
                fontFamily: 'body',
                justifyContent: 'center',
              }}
            >
              <ThemeUI.Box
                sx={{
                  mb: 4,
                  whiteSpace: 'pre-wrap',
                }}
              >
                <HtmlContent
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    lineHeight: 4,
                  }}
                >
                  {translate('footer.about-text')}
                </HtmlContent>
              </ThemeUI.Box>
            </ThemeUI.Flex>
            {/* END: Left Column */}

            {/* Right Column */}
            <ThemeUI.Flex
              sx={{
                'flexDirection': 'column',
                'flexShrink': 0,

                '> *:not(:last-of-type)': {
                  mb: 4,
                },
              }}
            >
              <FooterNavBar />

              <SocialLinks items={socialMedia} />

              {/* Right Column logos */}
              <Lazyload once height={40}>
                <ThemeUI.Flex
                  sx={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 2,
                  }}
                >
                  <ThemeUI.Box
                    as="a"
                    href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJamRDVWtoWWNEWTNWak42UjFKbVRIUnNjbTFZUlVFOVBTSXNJblpoYkhWbElqb2lRbXdyYlhOQ1EzbFRaV0ZYVmxKalpUWm5ZbTV6VVQwOUlpd2liV0ZqSWpvaVkyRmtNMkV4T1RZek1tVmhPR05qTlRNd01qZzFNekUyWmpsak5XRTVOVGt3WkRkaE9Ea3dORFk0TXpRM1pqa3hNalk0T1RjNE1tWXpOV1ZqWm1SaFlTSXNJblJoWnlJNklpSjk="
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: 'flex' }}
                  >
                    <ThemeUI.Image
                      alt="GCB license"
                      src="/images/gcb.avif"
                      loading="lazy"
                      sx={{
                        height: '30px',
                        width: 'auto',
                      }}
                    />
                  </ThemeUI.Box>
                  <NavLink to="/responsible-gaming" sx={{ display: 'flex' }}>
                    <ThemeUI.Image
                      alt="play responsibly 18+"
                      src="/images/play-responsibly.png"
                      loading="lazy"
                      sx={{
                        height: '30px',
                        width: 'auto',
                      }}
                    />
                  </NavLink>
                </ThemeUI.Flex>
              </Lazyload>
              {/* END: Right Column logos */}
            </ThemeUI.Flex>
            {/* END: Right Column */}
          </ThemeUI.Flex>
          {/* END: Columns Wrapper */}
        </ThemeUI.Box>
      </ThemeUI.Box>
      {/* END: Main Wrapper */}
    </footer>
  )
}

Herz.I18n.Loader.preload(['footer.about-text'], Footer)
